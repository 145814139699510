<template>
  <div>
    <PageTitle
      title="各門市客人消費登錄連結"
      btn="生成連結"
      btn2="匯出連結"
      :btnLoading="loading.linkUpdate"
      @btnClick="onGenerateLinks"
      @btn2Click="modal.export = true"
    />
    <FilterContainer>
      <el-input
        v-model="search.name"
        placeholder="搜尋門市名稱"
        clearable
        @clear="refresh(true)"
        @keypress.enter.native="refresh(true)"
      >
        <i
          slot="suffix"
          class="el-input__icon el-icon-search"
          @click="refresh(true)"
        />
      </el-input>
    </FilterContainer>
    <ManualSalesRecordLinksTable v-loading="loading.table" :tableData="tableData" @refresh="refresh" />
    <Pagination
      :curPage.sync="tableOptions.page"
      :pageLimit="tableOptions.pageLimit"
      :total="tableDataCount"
      @pageChange="refresh(false)"
    />

    <WarningDialog
      v-if="modal.warning"
      title="提醒"
      btnString="前往"
      hideCancel
      @close="modal.warning = false"
      @confirm="$router.push({name: 'StoreSetting'})"
    >
      <template slot="body">
        <div>
          尚未建立門市，請前往「
          <router-link class="text-primary-100" :to="{ name: 'StoreSetting' }">門市設定</router-link>
          」頁面進行設定
        </div>
      </template>
    </WarningDialog>

    <ExportOptionsDialog
      v-if="modal.export"
      allRange
      @close="modal.export = false"
      @export="modal.export = false, prepareExport()"
    />
    <ExportDialog
      v-if="modal.exporting"
      :inProgress="false"
      :isError="false"
      :percentage="100"
      :data="[]"
      :total="0"
      @close="resetExport"
    />
  </div>
</template>

<script>
import { defineComponent, onMounted, reactive } from 'vue'
import PageTitle from '@/components/Title/PageTitle.vue'
import FilterContainer from '@/components/Container/FiltersContainer.vue'
import ManualSalesRecordLinksTable from './components/ManualSalesRecordLinksTable.vue'
import WarningDialog from '@/components/Dialog/WarningDialog.vue'
import ExportOptionsDialog from '@/components/Dialog/ExportOptionsDialog.vue'
import ExportDialog from '@/components/Dialog/ExportDialog.vue'
import { GetSalesClientLink, GetSalesClientLinkCount, AutoUpdateSalesClientLink } from '@/api/sales'
import { useTable } from '@/use/table'
import { useRouter } from 'vue-router/composables'
import { getAllDataFromApi } from '@/utils/helper'
import { ExportExcel } from '@/utils/excel'
import { get, map } from 'lodash'
import { useShop } from '@/use/shop'

export default defineComponent({
  name: 'ParentComponent',
  components: { PageTitle, FilterContainer, ManualSalesRecordLinksTable, WarningDialog, ExportOptionsDialog, ExportDialog },
  setup (props, { emit }) {
    const router = useRouter()
    const { shopName } = useShop()
    const {
      shopId, loading, search, extendData, pageStartIndex,
      tableData, tableDataCount, tableOptions,
      fetchData, fetchDataCount,
    } = useTable()

    extendData('search', {
      name: null,
    })
    extendData('loading', {
      linkUpdate: false,
    })

    const modal = reactive({
      warning: false,
      export: false,
      exporting: false,
    })

    const getTableData = async () => {
      const payload = {
        shopId: shopId.value,
        start: pageStartIndex.value,
        limit: tableOptions.pageLimit,
        branchName: search.name || undefined,
      }
      await Promise.allSettled([
        fetchData(GetSalesClientLink, payload),
        fetchDataCount(GetSalesClientLinkCount, payload),
      ])
    }

    const onGenerateLinks = async () => {
      if (loading.linkUpdate) return
      loading.linkUpdate = true
      const [res, err] = await AutoUpdateSalesClientLink({ shopId: shopId.value })
      loading.linkUpdate = false
      if (err || !res.success) {
        window.$message.error(err || res.msg)
        return
      }

      // 檢查門市數量
      if (res.branchLength === 0) {
        modal.warning = true
        return
      }
      refresh()

      window.$message.success('更新成功 !')
    }

    const prepareExport = async () => {
      const payload = {
        shopId: shopId.value,
        start: 0,
        limit: 100,
      }
      const [res, err] = await GetSalesClientLinkCount({ shopId: shopId.value })
      if (err) {
        window.$message.error(err)
        return
      }
      const totalDataCount = res.count
      const [data, dataErr] = await getAllDataFromApi(
        totalDataCount,
        GetSalesClientLink,
        payload,
        true,
      )
      console.log(data)
      console.log(dataErr)
      await exportData(data)
    }
    const exportData = async (data) => {
      const computedData = map(data, i => {
        return {
          門市名稱: get(i, 'Branch.name'),
          連結: get(i, 'shortUrl'),
        }
      })
      const fileName = `${shopName.value}_消費登錄連結匯出`
      await ExportExcel(computedData, fileName, fileName)
      modal.exporting = true
    }
    const resetExport = () => {
      modal.exporting = false
    }

    const refresh = async (search = false) => {
      if (search) tableOptions.page = 1
      loading.table = true
      await getTableData()
      loading.table = false
    }

    onMounted(async () => {
      refresh()
    })

    return {
      modal,
      loading,
      search,
      tableData,
      tableDataCount,
      tableOptions,
      refresh,
      onGenerateLinks,
      prepareExport,
      resetExport,
    }
  },
})
</script>
